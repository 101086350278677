import * as S from './OtpTabItem.styles';
import Savings from '../Savings/Savings';
import React, { useContext } from 'react';
import FunnelContext from '../../../context/FunnelContext';

export const OtpTabItem = props => {
  const {
    hideSubsTab,
    initKlarna,
    hideOtpTab,
    language,
    hideOtpSavings,
    otpSavingsAddon,
    blockId,
    style,
    isSpanTextSame,
    priceSettings,
    discountColor,
    dotBorderColor,
    dotBackgroundColor
  } = props;

  const {
    currentCategory,
    extraObjects,
    currentTubIndex,
    setCurrentProduct,
    setExtraObjects,
    setCurrentCategory,
    pageDesign
  } = useContext(FunnelContext);

  const { tabOnetime = `One-time` } = language || {};

  if (hideOtpTab) {
    return ``;
  }

  return (
    <S.TabItem
      onClick={() => {
        if (typeof setCurrentCategory === 'function') {
          setCurrentCategory('onetime');
          setCurrentProduct(`onetime_${currentTubIndex}`);
          initKlarna('onetime');
          setExtraObjects(
            Object.assign(extraObjects, {
              currentCategory: 'onetime'
            })
          );
        }
      }}
      hideSubs={hideSubsTab}
      pageDesign={pageDesign}
      className={`otp ${
        currentCategory === `onetime` ? 'active' : ''
      } ${style}`}
    >
      <label htmlFor={`${blockId}_otp`}>
        <input
          onChange={() => {
            if (typeof setCurrentCategory === 'function') {
              setCurrentCategory('onetime');
              setCurrentProduct(`onetime_${currentTubIndex}`);
              initKlarna('onetime');
              setExtraObjects(
                Object.assign(extraObjects, {
                  currentCategory
                })
              );
            }
          }}
          id={`${blockId}_otp`}
          name={`${blockId}_tab`}
          type="radio"
          checked={currentCategory === `onetime` ? 'checked' : ''}
        />
        <S.TabItemDot
          pageDesign={pageDesign}
          className={`${
            currentCategory === `onetime` ? 'active' : ''
          } ${style}`}
          discountColor={discountColor}
          dotBorderColor={dotBorderColor}
          dotBackgroundColor={dotBackgroundColor}
        ></S.TabItemDot>
        <S.TabItemTitle
          pageDesign={pageDesign}
          className={style}
          isSpanTextSame={isSpanTextSame}
          discountColor={discountColor}
        >
          <p>{tabOnetime}&nbsp;</p>
          {!hideOtpSavings && (
            <Savings
              currentCategory="onetime"
              currentProduct={`onetime_${currentTubIndex}`}
              priceSettings={priceSettings}
              addon={otpSavingsAddon}
              language={language}
            />
          )}
        </S.TabItemTitle>
      </label>
    </S.TabItem>
  );
};
export default OtpTabItem;
