import styled from 'styled-components';

export const TabItemDot = styled.span`
  border: 1px solid #ccd2e3;
  width: 15px;
  height: 15px;
  margin-right: 8px;
  border-radius: 50%;
  position: static;
  display: grid;
  place-items: center;
  background-color: #fff;

  &.active {
    border: ${({ dotBorderColor = `#0573fb` }) =>
      `1px solid ${dotBorderColor}`};
  }

  &.active:after {
    width: 9px;
    height: 9px;
    display: block;
    position: static;
    background-color: ${({ dotBackgroundColor = `#0573fb` }) =>
      `${dotBackgroundColor}`};
    content: ' ';
    border-radius: 50%;
    top: 2px;
    left: 2px;
  }

  &.big-font-title {
    width: 16px;
    height: 16px;
    border: 1px solid #99a4c7;

    &.active {
      border: 1px solid #99a4c7;
    }

    &.active:after {
      width: 8px;
      height: 8px;
      background-color: ${({ discountColor }) =>
        discountColor ? `${discountColor}` : '#bc2e3e'};
    }
  }

  ${({
    pageDesign,
    dotBorderColor = `#001C72`,
    dotBackgroundColor = `#001C72`
  }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                width: 20px;
                height: 20px;
                &.active {
                    border: 1px solid ${dotBorderColor};
                  }
                &.active:after {
                    background-color: ${dotBackgroundColor};
                    width: 12px;
                    height: 12px;
                }
                @media (max-width: 375px) {
                    flex: none;
                    width: 16px;
                    height: 16px;
                    &.active:after {
                        width: 10px;
                        height: 10px;
                    }
                }
            `;
      default:
        return ``;
    }
  }};
`;

export const TabItem = styled.div`
  display: flex;
  position: relative;

  & label {
    cursor: pointer;
    display: flex;
    padding: 8px;

    & > input[type='radio'] {
      position: absolute;
      margin: 0;
      appearance: none;
      display: none;
    }
  }

  &.active {
    background: #fff;
  }

  ${({ hideSubs, hideOtp }) => {
    let styles = `
        background: #f4f4f4;
        flex: 0 0 50%;
        `;
    if (hideSubs || hideOtp) {
      styles = `
                flex: 0 0 100%;
                background: #FFF;
                & > label > input {
                    display: none;
                }
            `;
    }
    return styles;
  }}
  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                background: #E5E8F1 0% 0% no-repeat padding-box;
                    
                & label {
                   position: relative;
                   align-items: center;
                   padding: 16px 15px 15px;
                   font-size: 16px;
                   
                   @media (max-width: 425px) {
                       padding: 12px;
                   }

                   @media (max-width: 375px) {
                       padding: 10px;
                   }
                }
                `;
      default:
        return ``;
    }
  }}
  &.big-font-title {
    flex: 0 0 45%;

    & label {
      padding: 15px 8px;
      display: flex;
      align-items: center;

      @media (max-width: 400px) {
        padding: 12px 8px;
      }
    }

    @media (max-width: 1100px) {
      flex: 0 0 40%;
    }

    @media (max-width: 550px) {
      flex: 0 0 40%;
    }

    @media (max-width: 345px) {
      flex: 0 0 40%;
    }
  }
}
`;

export const TabItemTitle = styled.div`
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  margin-top: 1px;

  p {
    margin: 0;
  }

  & > span {
    font-size: ${({ isSpanTextSame }) => (isSpanTextSame ? 'inherit' : '16px')};
    line-height: 100%;
    color: ${({ discountColor = '#bc2e3e' }) => `${discountColor}`};
    font-size: ${({ isSpanTextSame }) => (isSpanTextSame ? 'inherit' : '14px')};

    @media (max-width: 550px) {
      font-size: 14px;
    }
  }

  @media (max-width: 550px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    font-size: 16px;
  }

  @media (max-width: 410px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 12px;
    & > span {
      font-size: 12px;
      letter-spacing: 0;
    }
  }

  &.two-lines {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  &.big-font-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 100%;
    width: calc(100% - 24px);
    & > p {
      white-space: nowrap;
    }
    @media (max-width: 1100px) {
      padding-top: 3px;
      margin-top: 0;
    }
    @media (max-width: 550px) {
      font-size: 16px;
      flex-direction: column;
      align-items: flex-start;
    }
    @media (max-width: 370px) {
      font-size: 14px;
    }
  }
`;
